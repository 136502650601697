@font-face {
  font-family: mouseMemoir;
  src: url(mouse-memoirs.regular.ttf);
}

.App {
  text-align: center;
  overflow-x: auto;
  overflow-y: auto;
}

.App-title {
  height: 3vmax;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ih-logo {
  height: 5vmax;
}

.App-header {
  height: 5vmax;
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: mouseMemoir;
  font-size: 1rem;
  color: blanchedalmond;
}

.App-logo {
  max-width: 100%;
}

.Applab-logo {
  height: 50px;
  border-radius: 50px;
  margin: 8px;
}

.App-banner {
  background-repeat:no-repeat;
  background-size: contain;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-body {
  padding: 8px;
  min-height: 20vmax;
  max-height: 50vmax;
  padding-left: 4%;
  padding-right: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: mouseMemoir;
  font-size: 4.25vw;
  background-color: #ec5e1d;
}

.App-body-small-font {
  font-size: 3.75vw;
}

.App-body-large-font {
  font-size: 5vw;
}

.App-body2 {
  min-height: 20vmax;
  padding: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: mouseMemoir;
  font-size: 3vw;
  background-color: #ec5e1d;
}

.App-Video {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  font-family: mouseMemoir;
  font-size: 5vw;
  color: #ec5e1d;
}

iframe { 
  width: 90%;
  aspect-ratio: 16 / 9;
}

.slider-container .dot {
  width: 100%;
  height: 100%;
  background-color: #121212;
  color: #121212;
  
  /* Add this */
  position:fixed;
  top:0;
  left:0;
}

.App-footer {
  height: 3.5vmax;
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: mouseMemoir;
  font-size: 1rem;
  color: #FAFAFA;
}

.App-divider-black {
  height: 0.5vmax;
  background-color: #121212;
}

.App-divider-orange {
  height: 0.5vmax;
  background-color: #ec5e1d !important;
}

.App-link {
  color: #61dafb;
}

.App-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: mouseMemoir;
  font-size: 4.25vw;
  background-color: #121212;
}

@keyframes PulseAnimation {
  0%, 100% {
    scale: 0.95;
  }
  50% {
    scale: 1;
  }
}

.App-download-button {
  margin: 2vh;
  border-radius: 60px;
  height: 60px;
  width: 240px;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: mouseMemoir;
  font-size: 1.5rem;
  color: #121212;
  animation: PulseAnimation 1.5s ease-in-out infinite;
}

.App-download-button:hover {
  background-color: #333333;
  color: #FAFAFA;
}

